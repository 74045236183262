import React, { useState } from "react";
import MKBox from "components/MKBox";
// import Grid2 from "@mui/material/Unstable_Grid2";
import NewMKBUtton from "stylizedMUIComponents";
import { useKeenSlider } from "keen-slider/react";
// import ReactPlayer from "react-player/youtube";
import "keen-slider/keen-slider.min.css";
import "./styles.css";

export default () => {
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      // slideChanged(slider) {
      //   setCurrentSlide(slider.track.details.rel);
      // },
      created() {
        setLoaded(true);
      },
    }
    // [
    //   (slider) => {
    //     let timeout;
    //     let mouseOver = false;
    //     function clearNextTimeout() {
    //       clearTimeout(timeout);
    //     }
    //     function nextTimeout() {
    //       clearTimeout(timeout);
    //       if (mouseOver) return;
    //       timeout = setTimeout(() => {
    //         slider.next();
    //       }, 2000);
    //     }
    //     slider.on("created", () => {
    //       slider.container.addEventListener("mouseover", () => {
    //         mouseOver = true;
    //         clearNextTimeout();
    //       });
    //       slider.container.addEventListener("mouseout", () => {
    //         mouseOver = false;
    //         nextTimeout();
    //       });
    //       nextTimeout();
    //     });
    //     slider.on("dragStarted", clearNextTimeout);
    //     slider.on("animationEnded", nextTimeout);
    //     slider.on("updated", nextTimeout);
    //   },
    // ]
  );

  return (
    <MKBox sx={{ width: "100vw", height: "100%" }}>
      <MKBox>
        <MKBox ref={sliderRef} className="keen-slider">
          <MKBox sx={{ pointerEvents: "none" }} className="keen-slider__slide number-slide1">
            {/* <ReactPlayer
              height="150%"
              width="100%"
              // config={{
              //   youtube: {
              //     playerVars: { showinfo: 0 },
              //   },
              // }}
              volume={0}
              loop
              muted
              playing
              url="https://www.youtube.com/watch?v=Bq4rmeIvJbs&t=285s"
            /> */}
            <video
              // src="https://ik.imagekit.io/AimiFirdhausShafie/IMG_5687.MOV/ik-video.mp4?updatedAt=1739077606909"
              width={window.innerWidth / window.innerHeight > 1 ? window.innerWidth : "auto"}
              height={window.innerWidth / window.innerHeight > 1 ? "auto" : window.innerHeight}
              // controls
              autoPlay
              muted
              loop
              playsInline
            >
              <source
                src="https://ik.imagekit.io/AimiFirdhausShafie/IMG_5846.MOV/ik-video.mp4?updatedAt=1740040182422"
                type="video/mp4"
              />
            </video>
          </MKBox>
          <MKBox className="keen-slider__slide number-slide2">
            <NewMKBUtton
              target="_blank"
              href="https://firebasestorage.googleapis.com/v0/b/radiakkm-website.appspot.com/o/Profile%20%20(1).pdf?alt=media&token=592f2351-8581-4fd9-a5ac-e15854b24b06"
            >
              Download our company profile
            </NewMKBUtton>{" "}
          </MKBox>
          {/* <MKBox className="keen-slider__slide number-slide3">3</MKBox>
          <MKBox className="keen-slider__slide number-slide4">4</MKBox>
          <MKBox className="keen-slider__slide number-slide5">5</MKBox>
          <MKBox className="keen-slider__slide number-slide6">6</MKBox> */}
        </MKBox>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
              // disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
              // disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
            />
          </>
        )}
      </MKBox>
      {/* {loaded && instanceRef.current && (
        <div className="dots">
          {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => (
            <button
              type="button"
              aria-label="Mute volume"
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={`dot${currentSlide === idx ? " active" : ""}`}
            />
          ))}
        </div>
      )} */}
    </MKBox>
  );
};

function Arrow({ disabled, onClick, left }) {
  return (
    <svg
      onClick={onClick}
      className={`arrow ${left ? "arrow--left" : "arrow--right"} ${
        disabled ? " arrow--disabled" : ""
      }`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
      {!left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </svg>
  );
}
